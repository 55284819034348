import { api } from './baseApi';

export const addTagTypes = ['Workflows'] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: build => ({
      getWorkflows: build.query<GetWorkflowsApiResponse, GetWorkflowsApiArg>({
        query: () => ({ url: `/v1/workflows` }),
        providesTags: ['Workflows'],
      }),
      createWorkflow: build.mutation<
        CreateWorkflowApiResponse,
        CreateWorkflowApiArg
      >({
        query: queryArg => ({
          url: `/v1/workflows`,
          method: 'POST',
          body: queryArg.createWorkflowRequest,
        }),
        invalidatesTags: ['Workflows'],
      }),
      createWorkflowRun: build.mutation<
        CreateWorkflowRunApiResponse,
        CreateWorkflowRunApiArg
      >({
        query: queryArg => ({
          url: `/v1/workflows/runs`,
          method: 'POST',
          body: queryArg.createWorkflowRunRequest,
        }),
        invalidatesTags: ['Workflows'],
      }),
      searchWorkflowRuns: build.query<
        SearchWorkflowRunsApiResponse,
        SearchWorkflowRunsApiArg
      >({
        query: queryArg => ({
          url: `/v1/workflows/runs/search`,
          params: {
            query: queryArg.query,
            expand: queryArg.expand,
            limit: queryArg.limit,
            skip: queryArg.skip,
            sort: queryArg.sort,
          },
        }),
        providesTags: ['Workflows'],
      }),
      searchWorkflowTasks: build.query<
        SearchWorkflowTasksApiResponse,
        SearchWorkflowTasksApiArg
      >({
        query: queryArg => ({
          url: `/v1/workflows/runs/tasks/search`,
          params: {
            query: queryArg.query,
            limit: queryArg.limit,
            skip: queryArg.skip,
            expand: queryArg.expand,
          },
        }),
        providesTags: ['Workflows'],
      }),
      getWorkflowTask: build.query<
        GetWorkflowTaskApiResponse,
        GetWorkflowTaskApiArg
      >({
        query: queryArg => ({
          url: `/v1/workflows/runs/tasks/${queryArg.workflowTaskId}`,
        }),
        providesTags: ['Workflows'],
      }),
      getWorkflowRun: build.query<
        GetWorkflowRunApiResponse,
        GetWorkflowRunApiArg
      >({
        query: queryArg => ({
          url: `/v1/workflows/runs/${queryArg.workflowRunId}`,
          params: { expand: queryArg.expand },
        }),
        providesTags: ['Workflows'],
      }),
      cancelWorkflowRun: build.mutation<
        CancelWorkflowRunApiResponse,
        CancelWorkflowRunApiArg
      >({
        query: queryArg => ({
          url: `/v1/workflows/runs/${queryArg.workflowRunId}/cancel`,
          method: 'PATCH',
        }),
        invalidatesTags: ['Workflows'],
      }),
      searchWorkflows: build.query<
        SearchWorkflowsApiResponse,
        SearchWorkflowsApiArg
      >({
        query: queryArg => ({
          url: `/v1/workflows/search`,
          params: {
            query: queryArg.query,
            sort: queryArg.sort,
            limit: queryArg.limit,
            skip: queryArg.skip,
            expand: queryArg.expand,
          },
        }),
        providesTags: ['Workflows'],
      }),
      validateWorkflowAction: build.mutation<
        ValidateWorkflowActionApiResponse,
        ValidateWorkflowActionApiArg
      >({
        query: queryArg => ({
          url: `/v1/workflows/validate-action`,
          method: 'POST',
          body: queryArg.body,
          params: { runner_mode: queryArg.runnerMode },
        }),
        invalidatesTags: ['Workflows'],
      }),
      validateWorkflowConfig: build.mutation<
        ValidateWorkflowConfigApiResponse,
        ValidateWorkflowConfigApiArg
      >({
        query: queryArg => ({
          url: `/v1/workflows/validate-config`,
          method: 'POST',
          body: queryArg.validateWorkflowConfigRequest,
        }),
        invalidatesTags: ['Workflows'],
      }),
      getWorkflow: build.query<GetWorkflowApiResponse, GetWorkflowApiArg>({
        query: queryArg => ({
          url: `/v1/workflows/${queryArg.workflowId}`,
          params: { expand: queryArg.expand },
        }),
        providesTags: ['Workflows'],
      }),
      deleteWorkflow: build.mutation<
        DeleteWorkflowApiResponse,
        DeleteWorkflowApiArg
      >({
        query: queryArg => ({
          url: `/v1/workflows/${queryArg.workflowId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Workflows'],
      }),
      updateWorkflowConfig: build.mutation<
        UpdateWorkflowConfigApiResponse,
        UpdateWorkflowConfigApiArg
      >({
        query: queryArg => ({
          url: `/v1/workflows/${queryArg.workflowId}/config`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['Workflows'],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as WorkflowsAPI };
export type GetWorkflowsApiResponse = /** status 200 OK */ GetWorkflowsResponse;
export type GetWorkflowsApiArg = void;
export type CreateWorkflowApiResponse = /** status 200 OK */ Workflow;
export type CreateWorkflowApiArg = {
  createWorkflowRequest: CreateWorkflowRequest;
};
export type CreateWorkflowRunApiResponse = /** status 200 OK */ WorkflowRun;
export type CreateWorkflowRunApiArg = {
  createWorkflowRunRequest: CreateWorkflowRunRequest;
};
export type SearchWorkflowRunsApiResponse =
  /** status 200 OK */ SearchWorkflowRunsResponse;
export type SearchWorkflowRunsApiArg = {
  /** The query string for searching workflow runs. Supported fields are: `id`, `workflow_id`, `project_id`, `status`, `runner_mode`, `cancelation_request` */
  query?: string;
  /** The fields to expand in the search results. Supported values are: `project`, `config.system`, `created_by` */
  expand?: string[];
  /** The maximum number of results to return. */
  limit?: number;
  /** The number of results to skip before applying the limit. */
  skip?: number;
  /** The sort order for the search results. */
  sort?: string;
};
export type SearchWorkflowTasksApiResponse =
  /** status 200 OK */ SearchWorkflowTasksResponse;
export type SearchWorkflowTasksApiArg = {
  /** The query string for searching workflow tasks. Supported fields are: `workflow_run_id` */
  query?: string;
  /** The maximum number of results to return. */
  limit?: number;
  /** The number of results to skip before applying the limit. */
  skip?: number;
  /** The fields to expand in the search results. Supported values are: `project`, `created_by` */
  expand?: string[];
};
export type GetWorkflowTaskApiResponse = /** status 200 OK */ WorkflowTask;
export type GetWorkflowTaskApiArg = {
  /** The ID of the workflow task to retrieve. */
  workflowTaskId: string;
};
export type GetWorkflowRunApiResponse = /** status 200 OK */ WorkflowRun;
export type GetWorkflowRunApiArg = {
  /** The ID of the workflow run to retrieve. */
  workflowRunId: string;
  /** The fields to expand in the response. Supported values are: `project`, `config.system`, `created_by` */
  expand?: string[];
};
export type CancelWorkflowRunApiResponse = /** status 200 OK */ WorkflowRun;
export type CancelWorkflowRunApiArg = {
  /** The ID of the workflow run to cancel. */
  workflowRunId: string;
};
export type SearchWorkflowsApiResponse =
  /** status 200 OK */ SearchWorkflowsResponse;
export type SearchWorkflowsApiArg = {
  /** The query string for searching workflows. Supported fields are: `id`, `project_id` */
  query?: string;
  /** The sort order for the search results. */
  sort?: string;
  /** The maximum number of results to return. */
  limit?: number;
  /** The number of results to skip before applying the limit. */
  skip?: number;
  /** The fields to expand in the search results. Supported values are: `project`, `created_by`, `updated_by`, `latest_run` */
  expand?: string[];
};
export type ValidateWorkflowActionApiResponse =
  /** status 200 OK */ ValidateWorkflowActionResponse;
export type ValidateWorkflowActionApiArg = {
  /** The runner mode to validate the action for. If this field is not UNSET, take the runner mode (cloud or hybrid) into account. This primarily affects which connections can be used in an action; those with Gretel-managed credentials encryption can't be used in a Hybrid workflow, and vice versa. */
  runnerMode?:
    | 'RUNNER_MODE_UNSET'
    | 'RUNNER_MODE_CLOUD'
    | 'RUNNER_MODE_HYBRID'
    | 'RUNNER_MODE_INVALID';
  body: object;
};
export type ValidateWorkflowConfigApiResponse = /** status 200 OK */ string;
export type ValidateWorkflowConfigApiArg = {
  validateWorkflowConfigRequest: ValidateWorkflowConfigRequest;
};
export type GetWorkflowApiResponse = /** status 200 OK */ Workflow;
export type GetWorkflowApiArg = {
  /** The ID of the workflow to retrieve. */
  workflowId: string;
  /** The fields to expand in the response. Supported values are: `project`, `created_by`, `updated_by`, `latest_run` */
  expand?: string[];
};
export type DeleteWorkflowApiResponse = /** status 200 OK */ string;
export type DeleteWorkflowApiArg = {
  /** The ID of the workflow to delete. */
  workflowId: string;
};
export type UpdateWorkflowConfigApiResponse = /** status 200 OK */ Workflow;
export type UpdateWorkflowConfigApiArg = {
  /** The ID of the workflow to update. */
  workflowId: string;
  body: string;
};
export type UserProfileImage = {
  small?: string;
  medium?: string;
  large?: string;
};
export type UserProfile = {
  id?: string;
  firstname?: string;
  lastname?: string;
  image?: UserProfileImage;
  email?: string;
};
export type CloudProviderInfoAws = {};
export type CloudProviderInfoGcp = {};
export type CloudProviderInfoAzure = {};
export type ClusterCloudProviderInfo = {
  region?: string;
  aws?: CloudProviderInfoAws;
  gcp?: CloudProviderInfoGcp;
  azure?: CloudProviderInfoAzure;
};
export type ClusterStatus = {
  health_status?:
    | 'HEALTH_STATUS_UNKNOWN'
    | 'HEALTH_STATUS_HEALTHY'
    | 'HEALTH_STATUS_DEGRADED'
    | 'HEALTH_STATUS_UNHEALTHY';
};
export type ConfigAsymmetricKeyMetadata = {
  key_id?: string;
  algorithm?: 'UNKNOWN_ALGORITHM' | 'RSA_4096_OAEP_SHA256';
  public_key_pem?: string;
};
export type ClusterConfig = {
  asymmetric_key?: ConfigAsymmetricKeyMetadata;
};
export type Cluster = {
  guid?: string;
  name?: string;
  owner_guid?: string;
  owner_profile?: UserProfile;
  cloud_provider?: ClusterCloudProviderInfo;
  cloud_provider_type?: 'UNKNOWN' | 'AWS' | 'GCP' | 'AZURE';
  status?: ClusterStatus;
  created_at?: string;
  last_checkin_time?: string;
  config?: ClusterConfig;
  chart_version?: string;
  app_version?: string;
};
export type Project = {
  id?: string;
  uid?: string;
  name?: string;
  display_name?: string;
  description?: string;
  long_description?: string;
  owner?: string;
  color?: string;
  public?: boolean;
  domain_guid?: string;
  runner_mode?:
    | 'RUNNER_MODE_UNSET'
    | 'RUNNER_MODE_CLOUD'
    | 'RUNNER_MODE_HYBRID'
    | 'RUNNER_MODE_INVALID';
  cluster_guid?: string;
  cluster?: Cluster;
  modified?: string;
  created?: string;
};
export type StatusDetails = {
  message?: string;
};
export type WorkflowRunCancellationRequest = {
  requested_by: string;
  requested_at: string;
};
export type WorkflowRun = {
  id: string;
  workflow_id: string;
  project_id: string;
  project?: Project;
  cluster_guid?: string;
  config?: object;
  config_text?: string;
  runner_mode:
    | 'RUNNER_MODE_UNSET'
    | 'RUNNER_MODE_CLOUD'
    | 'RUNNER_MODE_HYBRID'
    | 'RUNNER_MODE_INVALID';
  status:
    | 'RUN_STATUS_UNKNOWN'
    | 'RUN_STATUS_CREATED'
    | 'RUN_STATUS_PENDING'
    | 'RUN_STATUS_ACTIVE'
    | 'RUN_STATUS_ERROR'
    | 'RUN_STATUS_LOST'
    | 'RUN_STATUS_COMPLETED'
    | 'RUN_STATUS_CANCELLING'
    | 'RUN_STATUS_CANCELLED';
  status_details?: StatusDetails;
  created_by: string;
  created_at: string;
  updated_at?: string;
  pending_at?: string;
  active_at?: string;
  error_at?: string;
  lost_at?: string;
  cancelled_at?: string;
  lease_expires_at?: string;
  cancellation_request?: WorkflowRunCancellationRequest;
  created_by_profile?: UserProfile;
  total_compute_time_sconds?: number;
};
export type Workflow = {
  id: string;
  name: string;
  project_id: string;
  project?: Project;
  config?: object;
  config_text?: string;
  runner_mode?:
    | 'RUNNER_MODE_UNSET'
    | 'RUNNER_MODE_CLOUD'
    | 'RUNNER_MODE_HYBRID'
    | 'RUNNER_MODE_INVALID';
  created_by: string;
  created_by_profile?: UserProfile;
  updated_by?: string;
  updated_by_profile?: UserProfile;
  created_at: string;
  updated_at?: string;
  next_scheduled_run?: string;
  latest_run?: WorkflowRun;
};
export type GetWorkflowsResponse = {
  workflows?: Workflow[];
};
export type GoogleProtobufAny = {
  '@type'?: string;
  [key: string]: any;
};
export type Status = {
  code?: number;
  message?: string;
  details?: GoogleProtobufAny[];
};
export type CreateWorkflowRequest = {
  name?: string;
  project_id: string;
  config?: object;
  config_text?: string;
  runner_mode?:
    | 'RUNNER_MODE_UNSET'
    | 'RUNNER_MODE_CLOUD'
    | 'RUNNER_MODE_HYBRID'
    | 'RUNNER_MODE_INVALID';
};
export type CreateWorkflowRunRequest = {
  workflow_id: string;
  config?: object;
  config_text?: string;
};
export type SearchWorkflowRunsResponse = {
  runs?: WorkflowRun[];
  total: number;
};
export type WorkflowTask = {
  id: string;
  workflow_run_id: string;
  project_id: string;
  project?: Project;
  log_location: string;
  status:
    | 'RUN_STATUS_UNKNOWN'
    | 'RUN_STATUS_CREATED'
    | 'RUN_STATUS_PENDING'
    | 'RUN_STATUS_ACTIVE'
    | 'RUN_STATUS_ERROR'
    | 'RUN_STATUS_LOST'
    | 'RUN_STATUS_COMPLETED'
    | 'RUN_STATUS_CANCELLING'
    | 'RUN_STATUS_CANCELLED';
  action_name: string;
  action_type: string;
  error_msg?: string;
  error_code?: number;
  stack_trace?: string;
  created_by: string;
  created_by_profile?: UserProfile;
  created_at: string;
  updated_at?: string;
  pending_at?: string;
  active_at?: string;
  error_at?: string;
  lost_at?: string;
  completed_at?: string;
  cancelled_at?: string;
  total_compute_time_sconds?: number;
};
export type SearchWorkflowTasksResponse = {
  tasks?: WorkflowTask[];
  total: number;
};
export type SearchWorkflowsResponse = {
  workflows?: Workflow[];
  total: number;
};
export type ValidateWorkflowActionResponse = {
  status?:
    | 'VALIDATION_STATUS_UNKNOWN'
    | 'VALIDATION_STATUS_VALIDATING'
    | 'VALIDATION_STATUS_VALID'
    | 'VALIDATION_STATUS_INVALID';
  message?: string;
};
export type ValidateWorkflowConfigRequest = {
  config: object;
  runner_mode?:
    | 'RUNNER_MODE_UNSET'
    | 'RUNNER_MODE_CLOUD'
    | 'RUNNER_MODE_HYBRID'
    | 'RUNNER_MODE_INVALID';
};
export const {
  useGetWorkflowsQuery,
  useCreateWorkflowMutation,
  useCreateWorkflowRunMutation,
  useSearchWorkflowRunsQuery,
  useSearchWorkflowTasksQuery,
  useGetWorkflowTaskQuery,
  useGetWorkflowRunQuery,
  useCancelWorkflowRunMutation,
  useSearchWorkflowsQuery,
  useValidateWorkflowActionMutation,
  useValidateWorkflowConfigMutation,
  useGetWorkflowQuery,
  useDeleteWorkflowMutation,
  useUpdateWorkflowConfigMutation,
} = injectedRtkApi;
